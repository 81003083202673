.input {
  background-color: var(--color-bg-primary);
  background-image: none;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  box-shadow: none;
  color: var(--color-text-body);
  font-size: var(--text-sm);
  margin: 0;
  padding: var(--spacing-2) var(--spacing-3);
  transition:
    border-color ease-in-out 0.15s,
    background-color ease-in-out 0.15s,
    background-image ease-in-out 0.15s,
    background-position ease-in-out 0.15s,
    boxshadow ease-in-out 0.15s,
    padding ease-in-out 0.15s;

  &::placeholder {
    color: var(--color-slate-500);
  }
}

.nakedInput {
  background-color: transparent;
  border-color: transparent;
  margin-left: -0.2em;
  margin-right: -0.2em;
  min-width: 10px;
  overflow: hidden;
  padding: 0.2em;
  position: relative;
  text-overflow: ellipsis;
  width: calc(100% + 0.4em);

  &:active,
  &:focus,
  &:hover {
    background-color: var(--color-slate-100);
    border-color: var(--color-decorative-secondary);
    padding-right: 1.1em;
  }
}

.number {
  text-align: right;
}
