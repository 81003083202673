.root {
  & > div {
    flex-direction: column;

    & > :last-child {
      margin-top: var(--spacing-1);
    }

    &.center {
      align-items: center;
    }
    
    &.left {
      align-items: left
    }
  }
}

.image {
  max-height: 150px;
  max-width: 100%;
}
