.root {
  --l-logo-height: 64px;

  align-items: center;
  background: radial-gradient(
      circle at left center,
      rgb(87, 169, 227),
      rgb(47, 109, 172) 50%,
      rgb(33, 76, 120)
    )
    rgb(87, 169, 227);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  & .footer {
    border: 1px solid var(--color-text-body-inverted);
    border-radius: var(--rounded);
    color: var(--color-text-body-inverted);
    /* Add margin to vertically center everything: logo height - demo height */
    margin-bottom: calc(var(--l-logo-height) - 54px);
    margin-top: var(--spacing-8);
    padding: var(--spacing-4) var(--spacing-12);
  }

  & .logo {
    margin-bottom: var(--spacing-8);
    max-height: var(--l-logo-height);
    max-width: 100%;
  }
}

.header {
  margin-bottom: var(--spacing-6);

  & > h1 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.title {
  font-size: var(--text-2xl);
  line-height: var(--leading-2xl);
}

.wrapper {
  background-color: var(--color-bg-primary);
  border-radius: var(--rounded);
  max-width: 100%;
  padding: var(--spacing-12);
  width: var(--content-max-width-v2);
}
