.previewWrapper {
  align-items: center;
  background: var(--color-slate-200);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  display: flex;
  flex-shrink: 0;
  /* From the Figma design */
  height: 192px;
  justify-content: center;
  overflow: hidden;
  /* From the Figma design */
  width: 240px;
}

.preview {
  border: none;
  /* Scaling messes with height needs to match design, using a relative size */
  height: 21rem;
  overflow: hidden;
  pointer-events: none;
  transform: scale(0.5);
}

.loaded {
  /* From the Figma design */
  padding: 24px 41px 0 41px;
}

/* Desktop, Tablet landscape, should be --breakpoint-lg */
@media (max-width: 960px) {
  .previewWrapper {
    width: 100%;
  }
}
