.root {
  max-width: 100%;
  position: relative;

  & > * {
    width: 100%;
  }

  &.isClearable {
    /* 
      Only add extra padding right to account for the clear button if the user has typed something.
      This prevents cutting off the placeholder text if the input is small enough to need the extra space.
    */
    & > input:not([value='']) {
      padding-right: calc(
        var(--spacing-3) * 2 + var(--spacing-2) + var(--spacing-half)
      );
    }

    & > .clear {
      color: var(--color-decorative-secondary);
      cursor: pointer;
      display: none;
      line-height: var(--leading-none);
      margin: var(--spacing-3);
      padding-right: 0;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
      width: auto;

      &:hover {
        color: var(--color-text-body);
      }

      &.hasValue {
        display: inline-block;
      }
    }
  }
}

.spinner {
  margin: var(--spacing-2);
  position: absolute;
  right: 0;
  top: 0;
}
