.root {
  border-radius: var(--rounded-lg);
  display: flex;
  flex-direction: column;
  max-width: var(--content-max-width);
  position: relative;

  &.wide {
    max-width: var(--content-extra-width);
  }

  &.full {
    max-width: none;
  }
}

/* Add margin to consecutive cards */
.root + .root {
  margin-top: var(--spacing-8);
}

.default {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
}

.disabled {
  background: var(--color-slate-50);
  border: 1px solid var(--color-decorative-secondary);
}

.selected {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-sky-700);
}

.sticky {
  position: sticky;
  top: var(--spacing-4);
}
