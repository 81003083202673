.back {
  margin-bottom: var(--spacing-6);
}

.button {
  margin-bottom: var(--spacing-6);
}

.reachout {
  margin-bottom: 0;
}

.resend {
  margin-bottom: var(--spacing-6);
}
