.help {
  color: var(--color-slate-500);
  display: block;
  font-size: var(--text-sm);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.root {
  display: block;

  & label + .help,
  & span + .help {
    margin-top: 0;
  }
}

.optional,
.required {
  font-weight: normal;
}

.element {
  display: flex;
  width: 100%;

  &::placeholder {
    color: var(--color-slate-500);
  }

  /* TODO: Remove this rule as it is causing unintended side effects. */
  & > *:not(.prefix):not(.suffix) {
    flex-grow: 1;
  }
}

.error {
  color: var(--color-danger);
  composes: help;
}

.prefix,
.suffix {
  align-items: center;
  background-color: var(--color-slate-100);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  color: var(--color-slate-500);
  display: flex;
  padding: 0 var(--spacing-3);
}

.prefix {
  border-bottom-right-radius: var(--rounded-none);
  border-right: 0;
  border-top-right-radius: var(--rounded-none);
}

.suffix {
  border-bottom-left-radius: var(--rounded-none);
  border-left: 0;
  border-top-left-radius: var(--rounded-none);
}

.hasPrefix {
  & input,
  & select,
  & textarea,
  & :global(.react-select__control) {
    border-bottom-left-radius: var(--rounded-none);
    border-top-left-radius: var(--rounded-none);
  }
}

.hasSuffix {
  & input,
  & select,
  & textarea,
  & :global(.react-select__control) {
    border-bottom-right-radius: var(--rounded-none);
    border-top-right-radius: var(--rounded-none);
  }
}

.readOnly {
  color: var(--color-slate-500);
  font-weight: 400;
}

[data-disabled] {
  & input[disabled],
  & select[disabled],
  & textarea[disabled],
  & :global(.react-select__control) {
    background-color: var(--color-bg-disabled);
    color: var(--color-text-disabled);
    cursor: not-allowed;
  }
}

[data-error] {
  & input,
  & select,
  & textarea,
  & :global(.react-select__control),
  & .prefix,
  & .suffix {
    border-color: var(--color-danger);
  }
}

.suffix :global(.react-select__control) {
  background-color: var(--color-slate-100);
  border-bottom-left-radius: var(--rounded-none);
  border-bottom-right-radius: var(--rounded-md);
  border-color: var(--color-decorative-secondary);
  border-top-left-radius: var(--rounded-none);
  border-top-right-radius: var(--rounded-md);
  color: var(--color-slate-600);
}

.suffix :global(.react-select__value-container) {
  padding: 0 var(--spacing-2);
  /* Shrunken by container, achieves desired width */
  width: calc(var(--spacing-6) * 3);

  & :global(.react-select__single-value) {
    color: var(--color-slate-500);
  }

  & > :last-child {
    margin: 0;
    padding: 0 var(--spacing-2);
  }
}

.suffix:has(:global(.react-select)) {
  border: none;
  padding: 0;
}
