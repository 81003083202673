.disabled {
  color: var(--color-disabled);
}

.label,
.span {
  color: var(--color-text-heading);
  display: inline-block;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  line-height: var(--leading-sm);
  margin-bottom: var(--spacing-2);
  max-width: 100%;
}
