.root {
  & > :not(div) {
    display: block;
    margin-bottom: var(--spacing-3);
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}

.signin {
  margin-top: calc(var(--spacing-6) * 2);
}

/* Must target a.demo to override the default hover */
a.demo {
  color: var(--color-white);
  font-weight: var(--font-bold);

  &:active,
  &:focus,
  &:hover {
    color: var(--color-slate-200);
  }
}
