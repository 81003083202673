/* stylelint-disable no-descending-specificity */
.contentWrapper {
  margin: 0 auto;
  max-width: var(--breakpoint-xl);
  padding: 0 var(--spacing-3) 0 calc(var(--spacing-3) + var(--spacing-2));
  position: relative;

  & > * {
    border: 1px solid var(--color-decorative-secondary);
    max-width: var(--conent-extra-width);
  }
}

.description {
  display: block;
  font-weight: var(--font-normal);
  padding-top: var(--spacing-2);
}

.root {
  background-color: var(--color-bg-primary);
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-3);
  position: relative;
  transition: box-shadow 100ms ease-in, transform 100ms ease-in;

  &:hover {
    box-shadow: var(--drop-shadow-md);
    transform: translate(-1px, -1px);
  }

  & a {
    color: var(--color-text-body);
    text-decoration: none;
  }

  & header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-right: var(--icon-size-lg);
    min-height: var(--icon-size-lg);
    width: 100%;

    & > *,
    & > a > * {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: var(--spacing-2);
      }
    }

    & > a {
      display: flex;
      flex: 1 1 auto;
      padding-bottom: calc((var(--icon-size-lg) - (var(--leading-normal) * var(--text-sm))) / 2);
      padding-top: calc((var(--icon-size-lg) - (var(--leading-normal) * var(--text-sm))) / 2);
    }

    & > svg,
    & > a > svg {
      padding-top: calc(((var(--leading-normal) * var(--text-sm)) - var(--text-sm)) / 2);
    }

    & > h3,
    & > a > h3 {
      flex: 1 1 auto;
      font-size: var(--text-sm);
      font-weight: var(--font-semibold);
      line-height: var(--leading-sm);
      margin: 0 var(--spacing-4) 0 0;
      word-wrap: break-word;
    }
  }

  &.banner {
    margin-bottom: var(--spacing-2);

    & header {
      & > a {
        display: block;
        padding-left: var(--spacing-3);
      }

      & > h3 {
        line-height: var(--icon-size-lg);
        padding-left: var(--spacing-3);
      }

      & > time {
        line-height: var(--icon-size-lg);

        &:last-child {
          margin-right: var(--spacing-2);
        }
      }
    }

    & :global(.ReactCollapse--collapse) {
      padding-left: var(--spacing-3);
      transition: height 500ms;
    }

    & :global(.notification-icon) {
      font-size: 2rem;
      left: calc(-2rem + var(--spacing-2));
      position: absolute;
    }

    &.level2 {
      background-color: var(--color-red-50);

      & .contentWrapper > * {
        border-left: 4px solid var(--color-danger);
      }
    }

    &.level1 {
      background-color: var(--color-orange-50);

      & .contentWrapper > * {
        border-left: 4px solid var(--color-warning);
      }
    }

    &.level0 {
      background-color: var(--color-sky-50);

      & .contentWrapper > * {
        border-left: 4px solid var(--color-info);
      }
    }

    &.loading {
      background-color: var(--color-bg-primary);

      & .contentWrapper > * {
        border-left: 4px solid var(--color-slate-400);
      }
    }

    & .button {
      display: block;
      margin-top: var(--spacing-2);
      text-decoration: underline;

      &:before {
        content: '';
        display: block;
      }
    }

    & .description {
      max-width: var(--content-extra-width);
      overflow: hidden;
    }
  }

  &.card {
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-lg);
    box-shadow: var(--drop-shadow);
    margin-bottom: var(--spacing-2);
    margin-top: var(--spacing-2);
    max-width: var(--content-max-width);

    &.read {
      box-shadow: var(--drop-shadow-none);
    }

    &.level2 {
      border-left: 4px solid var(--color-danger);
    }

    &.level1 {
      border-left: 4px solid var(--color-warning);
    }

    &.level0 {
      border-left: 4px solid var(--color-info);
    }

    &.loading {
      background-color: var(--color-bg-primary);
      border-left: 4px solid var(--color-slate-400);
    }
  }
}

.actions {
  display: flex;

  & > :not(:first-child) {
    margin-left: var(--spacing-1);
  }
}

.badgeWrapper {
  display: block;

  &.card {
    max-width: var(--content-max-width);
  }
}

.closeButton {
  padding: var(--spacing-2) var(--spacing-3);
  width: var(--icon-size-lg);
}

.collapseButton {
  padding: 0 var(--spacing-3);
}

.collapseButton,
.closeButton {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-sm);
  color: var(--color-slate-900);
  cursor: pointer;
  display: block;
  height: var(--icon-size-lg);

  & svg {
    color: var(--color-text-body);
  }

  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-slate-100);
    border-color: var(--color-decorative-secondary);
    color: var(--color-sky-700);
  }
}
