.naked {
  display: inline-block;

  &:global(.active),
  &:active,
  &:focus,
  &:hover {
    color: inherit;
  }
}
