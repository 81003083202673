.root {
  margin-bottom: var(--spacing-2);
  padding-left: calc(var(--avatar-size-md) + var(--spacing-2));
  position: relative;
}

.info {
  margin-left: var(--spacing-2);
}

.number {
  background-color: var(--color-bg-secondary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-full);
  display: block;
  font-weight: var(--font-semibold);
  height: var(--avatar-size-md);
  left: 0;
  /* height - border */
  line-height: calc(var(--avatar-size-md) - var(--spacing-half));
  position: absolute;
  text-align: center;
  top: 0;
  transition:
    background-color var(--timing-300),
    color var(--timing-300);
  width: var(--avatar-size-md);

  &.link {
    cursor: pointer;

    &:hover {
      background-color: var(--color-action);
      border-color: var(--color-action);
      color: var(--color-text-body-inverted);
    }
  }
}

.title {
  color: var(--color-text-body);
  display: inline-block;
  font-size: var(--text-sm);
  font-weight: var(--font-semibold);
  line-height: var(--avatar-size-md);
  margin-bottom: 0;
  margin-top: 0;
}

.horizontal {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  & .number {
    position: relative;
  }

  & .title {
    line-height: var(--leading-sm);
    margin-top: var(--spacing-1-half);
    text-align: center;
  }

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }
}

.vertical {
  &:not(:last-child) {
    min-height: 48px;

    &::after {
      content: '';
      display: block;
      height: calc(100% - var(--avatar-size-md));
      left: calc(var(--avatar-size-md) / 2);
      position: absolute;
      top: calc(var(--avatar-size-md) + var(--spacing-1));
      width: 1px;
    }
  }
}

.active {
  &::after {
    background-color: var(--color-decorative-secondary);
  }

  & .number {
    background-color: var(--color-white);
    border-color: var(--color-action);
    color: var(--color-action);
  }
}

.finished {
  &::after {
    background-color: var(--color-action);
  }

  & .number {
    background-color: var(--color-green-50);
    border-color: var(--color-green-700);
    color: var(--color-green-700);

    & .link {
      &:hover {
        background-color: var(--color-green-700);
        border-color: var(--color-green-700);
        color: var(--color-green-50);
      }
    }
  }
}

.processing,
.reprocessing {
  &::after {
    background-color: var(--color-action);
  }

  & .number {
    background-color: var(--color-action);
    border-color: var(--color-action);
    color: var(--color-white);
  }

  & .title {
    color: var(--color-slate-900);
  }
}

.waiting {
  &::after {
    background-color: var(--color-decorative-secondary);
  }

  & .number {
    background-color: var(--color-bg-secondary);
    border-color: var(--color-slate-400);
  }

  & .number,
  & .title {
    color: var(--color-slate-400);
  }
}
