.root {
  & dd {
    margin-bottom: var(--spacing-2);

    & :last-child {
      margin-bottom: 0;
    }
  }

  & ol {
    padding-left: 1em;
  }

  & ul {
    padding-left: 0em;
  }
}

.error {
  color: var(--color-danger);
  display: block;
  font-size: var(--text-xs);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

@media (min-width: 960px) {
  .root {
    display: flex;
    flex-flow: row wrap;

    & dt {
      flex: 0 0 150px;
      margin-bottom: var(--spacing-2);
      padding-right: 1ex;
      text-align: right;

      &::after {
        content: ':';
      }
    }

    & dd {
      flex: 1 0 calc(100% - 150px);
    }
  }
}
