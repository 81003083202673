.block {
  display: flex;
  gap: var(--spacing-4);
}

.vertical {
  &.standard {
    & div {
      display: block;
    }
  }

  &.block {
    flex-direction: column;
  }
}

.horizontal {
  &.standard {
    & div {
      display: inline-block;

      &:not(:first-child) {
        margin-left: var(--spacing-4);
      }
    }
  }
}

.input {
  & input[type='radio'] {
    accent-color: var(--color-action);
    /* Undo meddling by bootstrap */
    margin: 0 var(--spacing-2) 0 0;
  }

  & input {
    margin-right: var(--spacing-2);

    &:not(:first-child) {
      margin-left: var(--spacing-4);
    }
  }

  & label {
    font-weight: normal;
    margin-bottom: 0;
  }
}
