*,
:after,
:before,
input[type='search'] {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg-primary);
  color: var(--color-text-body);
  font-family: var(--font-body);
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
}

body,
input {
  color: var(--color-text-body);
  line-height: var(--leading-sm);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-heading);
  font-family: inherit;
  font-weight: var(--font-semibold);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-6);
}

h1 {
  font-size: var(--text-3xl);
  font-weight: var(--font-bold);
  line-height: var(--leading-3xl);
}

h2 {
  font-size: var(--text-3xl);
  line-height: var(--leading-3xl);
}

h3 {
  font-size: var(--text-2xl);
  line-height: var(--leading-2xl);
}

h4 {
  font-size: var(--text-lg);
  line-height: var(--leading-lg);
}

a {
  color: var(--color-action);
  font-weight: var(--font-semibold);
  text-decoration: underline;
  text-underline-offset: var(--spacing-half);
  transition: color ease var(--timing-300);

  &:global(.active),
  &:active,
  &:focus,
  &:hover {
    color: var(--color-action-hover);
  }
}

p {
  margin: 0 0 var(--spacing-2);
}

/* TODO: Remove ul global style which was inherited from flatkit */
ul {
  list-style: none;
  margin-top: 0;
}

/* TODO: Remove img global vertical-align style which was inherited from flatkit */
img {
  vertical-align: middle;
}

dl {
  margin-block-start: 0;
}

dt {
  font-weight: var(--font-bold);
}

dd {
  margin-left: 0;
}

fieldset {
  min-width: 0;
  padding: 0;
}

label {
  color: var(--color-text-heading);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
}

pre {
  background-color: var(--color-slate-100);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-top: 0;
  overflow: auto;
  padding: var(--spacing-2);
}

table {
  border-collapse: collapse;
}
