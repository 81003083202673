.root {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--color-text-body);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font: inherit;
  gap: 1ch;
  padding: 0;
  position: relative;

  & .display {
    --l-offset: var(--spacing-half);
    --l-diameter: var(--icon-size);

    align-items: center;
    background-color: var(--color-slate-200);
    border-radius: 100vw;
    box-sizing: content-box;
    display: inline-flex;
    height: calc(var(--l-diameter) + var(--l-offset) * 2);
    justify-content: space-around;
    position: relative;
    transition: 250ms;
    width: calc(var(--l-diameter) * 2 + var(--l-offset) * 2);

    &::before {
      background-color: white;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      height: var(--l-diameter);
      left: var(--l-offset);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      transition: inherit;
      width: var(--l-diameter);
      will-change: transform;
      z-index: 2;
    }
  }

  &:focus .display {
    outline: 1px auto -webkit-focus-ring-color;
    outline-offset: 2px;
  }

  &[aria-pressed='true'] {
    & .display {
      background-color: var(--color-sky-700);
    }

    & .display::before {
      transform: translate(100%, -50%);
    }
  }

  &[disabled] {
    & .display {
      background-color: var(--color-slate-200);
      color: var(--color-slate-800);
      cursor: not-allowed;
      opacity: 50%;
    }

    &[aria-pressed='true'] .display {
      background-color: var(--color-sky-950);
    }
  }

  &:focus,
  &:focus:not(:focus-visible) .display {
    outline: 0;
  }

  /* :dir(rtl) does not work as expected */
  &[dir='rtl'] {
    & .display::before {
      left: auto;
      right: var(--l-offset);
    }

    &[aria-pressed='true'] .display::before {
      transform: translate(-100%, -50%);
    }

    & .cross {
      padding-left: var(--spacing-half);
      padding-right: 0;
    }

    & .checkMark {
      padding-left: 0;
      padding-right: var(--spacing-half);
    }
  }

  & .icon {
    color: inherit;
    display: inline-block;
    fill: currentcolor;
    font-size: var(--text-xs);
    height: 1em;
    overflow: hidden;
    vertical-align: middle;
    width: 1em;

    &.cross {
      color: var(--color-slate-600);
      padding-right: var(--spacing-half);
    }

    &.checkMark {
      color: var(--color-white);
      padding-left: var(--spacing-half);
    }
  }
}
