.root {
  align-items: center;
  background-color: var(--color-bg-secondary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-md);
  color: var(--color-text-body);
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  padding: var(--spacing-3);
}

.content {
  width: 100%;
  word-wrap: break-word;
}

.label {
  margin-bottom: 0;
}
