.root {
  background-color: var(--color-orange-50);
  border: 1px solid var(--color-orange-500);
  color: var(--color-orange-700);
  font-size: var(--text-xs);
  line-height: var(--leading-none);
  margin-left: var(--spacing-1);
  /* Unset min-height */
  min-height: auto;
  padding: var(--spacing-half) var(--spacing-1);
  vertical-align: text-bottom;
}
