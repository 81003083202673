:root {
  --button-spacing-horizontal: var(--spacing-3);
  --button-spacing-vertical: var(--spacing-2);
}

.root {
  align-items: center;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-md);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--text-xs);
  font-weight: var(--font-semibold);
  height: var(--button-size);
  /* Match the line-height of the icon */
  line-height: var(--button-icon-size);
  margin-bottom: 0;
  padding: 0 var(--button-spacing-horizontal);
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: all ease var(--timing-300);
  user-select: none;
  white-space: nowrap;

  &:disabled:not(:global .loading),
  &.disabled:not(:global .loading) {
    background-color: var(--color-slate-200);
    border-color: var(--color-transparent);
    color: var(--color-slate-800);
    cursor: not-allowed;
    filter: alpha(opacity=50);
    opacity: 0.5;
    width: fit-content;
  }

  & :global .material-icons {
    font-size: 157.143%; /* 22px when font-size: 14px */
    vertical-align: middle;
  }

  & :global .svg-inline--fa {
    font-size: 128.5715%; /* 18px when font-size: 14px */
    vertical-align: middle;
  }
}

.prefix,
.suffix {
  height: var(--button-icon-size);
}

.prefix {
  margin-right: var(--spacing-2);
}

.suffix {
  margin-left: var(--spacing-2);
}

.danger {
  background-color: var(--color-red-600);
  border-color: var(--color-red-600);
  color: var(--color-red-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-red-700);
    border-color: var(--color-red-700);
    color: var(--color-red-50);
  }
}

.link {
  background-color: inherit;
  border: none;
  color: var(--color-action);
  cursor: pointer;
  font-size: var(--text-sm);
  height: auto;
  justify-content: flex-start;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: var(--spacing-half);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: inherit;
    color: var(--color-action-hover);
  }
}

.primary {
  background-color: var(--color-action);
  border-color: var(--color-action);
  color: var(--color-sky-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-action-hover);
    border-color: var(--color-action-hover);
    color: var(--color-sky-50);
  }
}

.icon,
.icon-outlined {
  height: var(--button-with-icon-size);
}

.icon {
  background-color: var(--color-transparent);
  border-color: var(--color-transparent);
  color: var(--color-slate-900);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-slate-200);
    border-color: var(--color-slate-200);
  }
}

.secondary,
.icon-outlined {
  background-color: var(--color-bg-primary);
  border-color: var(--color-slate-300);
  color: var(--color-slate-900);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-slate-100);
    border-color: var(--color-slate-300);
    color: var(--color-action);
  }
}

.clicked {
  background-color: var(--color-slate-100);
  border-color: var(--color-slate-300);
  color: var(--color-action);
}

.naked {
  background-color: inherit;
  border: none;
  color: inherit;

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: inherit;
    color: inherit;
  }
}

.tertiary {
  background-color: inherit;
  border-color: transparent;
  color: var(--color-action);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-slate-100);
    border-color: var(--color-slate-100);
    color: var(--color-action);
  }
}

.success {
  background-color: var(--color-green-700);
  border-color: var(--color-green-700);
  color: var(--color-green-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-green-800);
    border-color: var(--color-green-800);
    color: var(--color-green-50);
  }
}

.spinner {
  flex: 1;
  justify-content: center;
}

.fullWidth {
  justify-content: center;
  width: 100%;
}
