.block {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  display: flex;
  flex-grow: 1;
  padding: var(--spacing-3);

  /* Make the whole box clickable */
  & label {
    flex-grow: 1;
  }

  &.disabled {
    background-color: var(--color-bg-disabled);
    cursor: not-allowed;

    & > label {
      color: var(--color-text-disabled);
      cursor: not-allowed;
    }
  }

  &.checked {
    background-color: var(--color-sky-100);
    border-color: var(--color-action);
  }
}
