.root {
  border-top-left-radius: var(--rounded-lg);
  border-top-right-radius: var(--rounded-lg);

  &.none {
    padding: var(--spacing-0);
  }

  &.compact {
    padding: var(--spacing-3);
  }

  &.normal {
    padding: var(--spacing-4);
  }

  &.addVerticalGap {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  /* Add border and remove border radius to consecutive elements */
  & + .root {
    border-radius: var(--rounded-none);
    border-top: 1px solid var(--color-decorative-secondary);
  }

  /* Round the bottom corners if it's the last element */
  &:last-of-type {
    border-bottom-left-radius: var(--rounded-lg);
    border-bottom-right-radius: var(--rounded-lg);
  }
}

.content {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.title {
  color: var(--color-text-heading);
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  line-height: var(--leading-sm);
}

.description {
  margin-top: var(--spacing-1);
}
