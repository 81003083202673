.root {
  --height: 8px;

  background-color: var(--color-slate-200);
  border-radius: var(--rounded-md);
  height: var(--height);
  overflow: hidden;

  & .bar {
    background-color: var(--color-action);
    border-radius: var(--rounded-md);
    height: var(--height);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
    width: 100%;
  }
}
