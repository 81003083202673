.root {
  margin: 0 0 var(--spacing-8) 0;

  & .parentContent {
    margin: var(--spacing-6);
    padding: 0;
  }

  & h3.title {
    font-size: var(--text-base);
    line-height: var(--leading-base);
    margin-bottom: var(--spacing-6);
  }

  & h3.titleWithActions {
    /* Adjust height of title to match the actions positioned to the right */
    height: var(--button-size);
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-6);
  padding: 0;

  &:first-child {
    padding-top: 0;
  }
}

.toolbar {
  position: absolute;
  right: var(--spacing-6);
  top: var(--spacing-6);
}
