.nav {
  align-items: center;
  display: flex;
  gap: var(--spacing-4);
  justify-content: center;
  margin-top: var(--spacing-4);
}

.slideNum {
  /* Prevent next/prev buttons from repositioning upon slide change */
  --l-min-width: 60px;

  font-weight: var(--font-semibold);
  min-width: var(--l-min-width);
  text-align: center;
}

/*
* Flickity-specific global styles  
* These styles are required to render the base Flickity slider component 
*/

.root {
  & :global(.flickity-enabled) {
    position: relative;
  }

  & :global(.flickity-viewport) {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  & :global(.flickity-slider) {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  & :global(.flickity-slider > *) {
    margin-right: var(--spacing-6);
    width: 100%;
  }

  & :global(.flickity-enabled:focus) {
    outline: none;
  }

  & :global(.flickity-enabled.is-draggable) {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  & :global(.flickity-enabled.is-draggable .flickity-viewport) {
    cursor: grab;
  }

  & :global(.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down) {
    cursor: grabbing;
  }
}
