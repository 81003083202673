/* stylelint-disable no-descending-specificity */
/* Customized version of 'rc-tooltip/assets/bootstrap.css' */
.root {
  &:global(.rc-tooltip) {
    display: block;
    font-size: var(--text-xs);
    line-height: var(--leading-normal);
    position: absolute;
    visibility: visible;
    z-index: 1070;

    &.rc-tooltip-zoom-enter,
    &.rc-tooltip-zoom-leave {
      display: block;
    }
  }

  &:global(.rc-tooltip-hidden) {
    display: none;
  }

  &:global(.rc-tooltip-zoom-enter),
  &:global(.rc-tooltip-zoom-appear) {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    opacity: 0;

    &:global(.rc-tooltip-zoom-enter-active),
    &:global(.rc-tooltip-zoom-appear-active) {
      animation-name: rcToolTipZoomIn;
      animation-play-state: running;
    }
  }

  &:global(.rc-tooltip-zoom-leave) {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);

    &:global(.rc-tooltip-zoom-leave-active) {
      animation-name: rcToolTipZoomOut;
      animation-play-state: running;
    }
  }

  @keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: 50% 50%;
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: 50% 50%;
    }
  }

  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: 50% 50%;
    }

    100% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: 50% 50%;
    }
  }

  & :global .rc-tooltip-arrow {
    border-color: transparent;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;
  }

  & :global .rc-tooltip-inner {
    background-color: var(--color-tooltip-background);
    border-radius: var(--rounded-md);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    color: var(--color-tooltip);
    font-size: var(--text-sm);
    line-height: var(--leading-sm);
    min-height: 34px;
    padding: var(--spacing-2) calc(var(--spacing-2) * 1.25);
    text-align: left;
    text-decoration: none;
  }

  &:global(.rc-tooltip-placement-top),
  &:global(.rc-tooltip-placement-topLeft),
  &:global(.rc-tooltip-placement-topRight) {
    padding: 5px 0 9px 0;

    & :global .rc-tooltip-arrow {
      border-top-color: var(--color-tooltip-background);
      border-width: 5px 5px 0;
      bottom: var(--spacing-1);
      margin-left: -5px;
    }

    & :global .rc-tooltip-inner {
      min-height: unset;
    }
  }

  &:global(.rc-tooltip-placement-right),
  &:global(.rc-tooltip-placement-rightTop),
  &:global(.rc-tooltip-placement-rightBottom) {
    padding: 0 5px 0 9px;

    & :global .rc-tooltip-arrow {
      border-right-color: var(--color-tooltip-background);
      border-width: 5px 5px 5px 0;
      left: var(--spacing-1);
      margin-top: -5px;
    }
  }

  &:global(.rc-tooltip-placement-bottom),
  &:global(.rc-tooltip-placement-bottomLeft),
  &:global(.rc-tooltip-placement-bottomRight) {
    padding: 9px 0 5px 0;

    & :global .rc-tooltip-arrow {
      border-bottom-color: var(--color-tooltip-background);
      border-width: 0 5px 5px;
      margin-left: -5px;
      top: var(--spacing-1);
    }

    & :global .rc-tooltip-inner {
      min-height: unset;
    }
  }

  &:global(.rc-tooltip-placement-left),
  &:global(.rc-tooltip-placement-leftTop),
  &:global(.rc-tooltip-placement-leftBottom) {
    padding: 0 9px 0 5px;

    & :global .rc-tooltip-arrow {
      border-left-color: var(--color-tooltip-background);
      border-width: 5px 0 5px 5px;
      margin-top: -5px;
      right: var(--spacing-1);
    }
  }

  &:global(.rc-tooltip-placement-top .rc-tooltip-arrow) {
    left: 50%;
  }

  &:global(.rc-tooltip-placement-topLeft .rc-tooltip-arrow) {
    left: 15%;
  }

  &:global(.rc-tooltip-placement-topRight .rc-tooltip-arrow) {
    right: 5%;
  }

  &:global(.rc-tooltip-placement-right .rc-tooltip-arrow) {
    top: 50%;
  }

  &:global(.rc-tooltip-placement-rightTop .rc-tooltip-arrow) {
    margin-top: 0;
    top: 15%;
  }

  &:global(.rc-tooltip-placement-rightBottom .rc-tooltip-arrow) {
    bottom: 15%;
  }

  &:global(.rc-tooltip-placement-left .rc-tooltip-arrow) {
    top: 50%;
  }

  &:global(.rc-tooltip-placement-leftTop .rc-tooltip-arrow) {
    margin-top: 0;
    top: 15%;
  }

  &:global(.rc-tooltip-placement-leftBottom .rc-tooltip-arrow) {
    bottom: 15%;
  }

  &:global(.rc-tooltip-placement-bottom .rc-tooltip-arrow) {
    left: 50%;
  }

  &:global(.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow) {
    left: 15%;
  }

  &:global(.rc-tooltip-placement-bottomRight .rc-tooltip-arrow) {
    right: 15%;
  }
}
