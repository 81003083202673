.root {
  max-width: var(--content-max-width);

  /* Added here for specificity to override `& > *`. */
  & .title {
    color: var(--color-text-heading);
    font-size: var(--text-lg);
    font-weight: var(--font-semibold);
    line-height: var(--leading-lg);
    margin: 0;
    max-width: var(--content-max-width);
  }

  & + .description {
    margin-top: var(--spacing-2);
  }

  /* Added here for specificity to override `& > *`. */
  & .description {
    margin-bottom: var(--spacing-8);
    max-width: var(--content-max-width-v2);
  }

  &.wide {
    max-width: none;
  }

  & > * {
    margin-bottom: var(--spacing-6);
  }
}

.actions {
  margin-bottom: 0;
  margin-top: var(--spacing-6);

  & > ul {
    text-align: left;
  }
}
